import React, { FC } from 'react';
import Banner from 'gatsby-theme-husky/src/common/Banner';
import Button from 'gatsby-theme-husky/src/common/Button';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import { navigateToPage } from 'gatsby-theme-husky/src/utils/functions';
import { BannerProps } from 'gatsby-theme-husky/src/common/Banner/models';
import VideoNaturalBanner from 'gatsby-theme-husky/src/components/VideoNaturalBanner';
import { PromoPanelProps } from 'gatsby-theme-husky/src/components/PromoPanel/models';

import 'gatsby-theme-husky/src/components/PromoPanel/PromoPanel.scss';
import './PromoPanelOverride.scss';

const PromoPanel: FC<PromoPanelProps> = ({
  promoPanel: {
    title,
    moreLabelText,
    moreLabelTextAriaLabel,
    viewAllButton,
    promoPanelBackgroundImage,
    promoPanelBackgroundImageAlt,
    promoBanners: { nodes: promoBannersNodes },
    videoBanners,
  },
  lang,
}) => {
  const { link, ariaLabel, text, withReverse } = viewAllButton?.length
    ? viewAllButton[0]
    : null || {};

  const handleNavigation = (pageLink) => navigateToPage(pageLink);

  return (
    <div className="promo-panel row" data-testid="promo-panel">
      {promoPanelBackgroundImage ? (
        <UmbracoImage
          image={promoPanelBackgroundImage}
          alt={promoPanelBackgroundImageAlt!}
          className="promo-panel__background-image"
        />
      ) : null}

      <div className="promo-panel__container">
        <h2 className="promo-panel__title">{title}</h2>
        <div className="promo-panel_row row flex-x-between">
          {promoBannersNodes.map(
            ({ title: bannerTitle, url, articleThumbnailImage, articleImageAlt }) => {
              const bannerProps = {
                button: {
                  ariaLabel: moreLabelTextAriaLabel,
                  link: [{ url }],
                  text: moreLabelText,
                  withReverse: '0',
                },
                imageAsBackground: articleThumbnailImage,
                imageAsBackgroundAlt: articleImageAlt,
                bannerTitleLink: [
                  {
                    name: bannerTitle,
                    url,
                  },
                ],
              } as BannerProps;

              return (
                <div
                  className="promo-panel__banner col--sm12 col--xl6"
                  key={bannerTitle}
                  data-testid="promo-banner"
                >
                  <Banner {...bannerProps} />
                </div>
              );
            }
          )}
        </div>
        {videoBanners ? (
          <div className="promo-panel__video-banners">
            {videoBanners.map(
              ({
                content,
                button,
                bannerVariant,
                imageAsBackgroundAlt,
                withWrapper,
                withVideo,
              }: BannerProps) => (
                <Banner
                  key={withVideo![0].link}
                  {...{
                    content,
                    button,
                    bannerVariant,
                    imageAsBackgroundAlt,
                    isWithWrapper: toBoolean(withWrapper!),
                    withVideo,
                    isPlayButton: true,
                  }}
                />
              )
            )}
          </div>
        ) : (
          <VideoNaturalBanner {...{ lang }} />
        )}
        {viewAllButton ? (
          <div className="promo-panel__row row">
            <div className="promo-panel__btn-container flex-x-center">
              <Button
                ariaLabel={ariaLabel!}
                isReversed={toBoolean(withReverse!)}
                onClick={() => handleNavigation(link!)}
              >
                {text}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PromoPanel;
